import { Parallax } from 'react-parallax';
import { Link } from 'react-router-dom';
import hotDesks from '../images/hot-desks.jpg'

export default function Memberships() {
  return (
    <>
      <Parallax
        className='page-section page-section-short bg-black'
        bgImage={hotDesks}
        bgImageAlt="hero"
        strength={400}
        bgImageStyle={{ opacity: '.5', objectFit: 'cover' }}
      >
        <div className="single-section">
          <h1 className='h1'>Memberships</h1>
        </div>
      </Parallax>
      {/* FEATURES */}
      <div className='page-section column'>
        <div className="single-section">
          <h2 className="h2">Choose your level of membership</h2>
        </div>
        <div className="section-grid-four">

          <div className="grid-item membership-item">
            <h3 className="h3">Hot Desk</h3>
            <p style={{ fontWeight: 'bold', fontSize: '18px' }} className="price">$75/month</p>
            <ul className='membership-features'>
              <li>Access to all open seating</li>
              <li>Fast wifi</li>
              <li>Free local coffee</li>
            </ul>
            <a target="_blank" rel="noreferrer" href={'https://buy.stripe.com/14k5kE43P3iZaZibIJ'} className="btn btn-dark">Sign up</a>
          </div>

          <div className="grid-item membership-item">
            <h3 className="h3">Basic Desk</h3>
            <p style={{ fontWeight: 'bold', fontSize: '18px' }} className="price">$125/month</p>
            <ul className='membership-features'>
              <li>A personal desk in the open space</li>
              <li>An extra 28" monitor</li>
              <li>All Hot Desk features</li>
            </ul>
            <a target="_blank" rel="noreferrer" href={'https://buy.stripe.com/4gw4gAbwh1aRaZieV6'} className="btn btn-dark">Sign up</a>
          </div>

          <div className="grid-item membership-item">
            <h3 className="h3">Premium Desk</h3>
            <p className="price"><s>$200/month</s>&nbsp;<span style={{ fontWeight: 'bold', fontSize: '18px' }}>$175/month</span></p>
            <ul className='membership-features'>
              <li>A designated, standing desk</li>
              <li>An extra 28" monitor</li>
              <li>A file cabinet to lock possessions in</li>
            </ul>
            <a target="_blank" rel="noreferrer" href={'https://buy.stripe.com/14k00keItf1Hd7q7sD'} className="btn btn-dark">Sign up</a>
          </div>

          <div className="grid-item membership-item">
            <h3 className="h3">Office Space</h3>
            <p style={{ fontWeight: 'bold', fontSize: '18px' }} className="price">Starting at $650/month</p>
            <ul className='membership-features'>
              <li>Your own, large, personal office</li>
              <li>Desk(s) that fit your specific needs</li>
              <li><i>Currently unavailable, reach out to join waitlist</i></li>
            </ul>
            <Link to={'/contact'} className="btn btn-dark">Contact us</Link>
          </div>

        </div>
      </div>
      {/* CTA */}
      <Parallax
        className='page-section bg-black'
        bgImage={'https://images.unsplash.com/photo-1542435503-956c469947f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80'}
        bgImageAlt="hero"
        strength={200}
        bgImageStyle={{ opacity: '.5', objectFit: 'cover' }}
      >
        <div className="single-section" style={{ textAlign: 'center', margin: 'auto' }}>
          <h2 className='h2'>Need a customized solution? <br /> Want more information?</h2>
          <Link to={'/contact'} className="btn">reach out</Link>
        </div>
      </Parallax>
    </>
  )
}