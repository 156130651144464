export default function TOS() {
  return (
    <>
      <h1 className="h2" style={{ margin: "1em" }}>NUMA SPACES TERMS OF SERVICE, LIABILITY, &amp; CODE OF CONDUCT</h1>
      <div style={{ marginLeft: "3em", marginRight: "3em", marginBottom: "3em" }}>
        <h2 className="h2" style={{ marginTop: "2em" }}>General Release Of Liability</h2>
        <br />
        I HEREBY ASSUME ALL OF THE RISKS OF PARTICIPATING IN ANY/ALL ACTIVITIES being conducted by NUMA SPACES LLC, including by way of example and not limitation, any risks that may arise
        from negligence or carelessness on the part of the persons or entities being released, from
        dangerous or defective equipment or property owned, maintained, or controlled by them, or
        because of their possible liability without fault.
        <br />
        I CERTIFY that I am physically fit, have sufficiently prepared or trained for participation in this
        activity, and have not been advised to not participate by a qualified medical professional.
        <br />
        I CERTIFY that there are no health-related reasons or problems which preclude my participation
        in this activity.
        <br />
        I acknowledge that this Accident Waiver and Release of Liability Form will be used by the event
        holders, sponsors, and organizers of the activity in which I may participate, and that it will
        govern my actions and responsibilities at said activity.
        <br />
        In consideration of my application and permitting me to participate in this activity, I hereby take
        action for myself, my executors, administrators, heirs, next of kin, successors, and assigns as
        follows:
        <ol style={{ paddingLeft: "2em" }}>
          <li>I WAIVE, RELEASE, AND DISCHARGE from any and all liability, including but not
            limited to, liability arising from the negligence or fault of the entities or persons released, for my
            death, disability, personal injury, property damage, property theft, or actions of any kind which
            may hereafter occur to me including my traveling to and from this activity, THE FOLLOWING
            ENTITIES OR PERSONS:
            NUMA SPACES LLC and/or their directors, officers, employees, volunteers,
            representatives, and agents, and the activity holders, sponsors, and volunteers;</li>
          <li>INDEMNIFY, HOLD HARMLESS, AND PROMISE NOT TO SUE the entities or persons
            mentioned in this paragraph from any and all liabilities or claims made as a result of participation
            in this activity, whether caused by the negligence of release or otherwise.
            I acknowledge that NUMA SPACES LLC and their directors, officers,
            volunteers, representatives, and agents are NOT responsible for errors, omissions, acts, or
            failures to act of any party or entity conducting a specific activity on their behalf.I acknowledge that this activity may involve a test of a person's physical and mental limits and
            carries with it the potential for death, serious injury, and property loss. The risks include, but are
            not limited to, those caused by terrain, facilities, temperature, weather, condition of participants,
            equipment, vehicular traffic, lack of hydration, and actions of other people, including, but not
            limited to, participants, volunteers, monitors, and/or producers of the activity. These risks are not
            only inherent to participants, but are also present for volunteers.
            I hereby consent to receive medical treatment which may be deemed advisable in the event of
            injury, accident, and/or illness during this activity.
            I understand while participating in this activity, I may be photographed. I agree to allow my
            photo, video, or film likeness to be used for any legitimate purpose by the activity holders,
            producers, sponsors, organizers, and assigns.
            The Accident Waiver and Release of Liability Form shall be construed broadly to provide a
            release and waiver to the maximum extent permissible under applicable law.</li>
        </ol>
        I CERTIFY THAT I HAVE READ THIS DOCUMENT AND I FULLY UNDERSTAND ITS
        CONTENT. I AM AWARE THAT THIS IS A RELEASE OF LIABILITY AND A
        CONTRACT AND I SIGN IT OF MY OWN FREE WILL.
        <br />
        <br />
        <br />
        <br />
        <br />
        <h2 className="h2" style={{ marginTop: "2em" }}>Terms of Service and Membership Agreement</h2>
        <ol style={{ paddingLeft: "2em" }}>
          <li>Furniture, Decor, and Desk Arrangement: Numa shall coordinate the arrangement of all items within the space.
            No member, guest, or any other individual without express permission from Numa Managers may move, rearrange, relocate, or bring in additional furniture, decor, or desks.
            This excludes items that fit on or within the desk space of members with permanent desks
            This excludes arrangements within an exclusively reserved space, such as a designated office space.
          </li>
          <li>
            Coffee: Coffee is an included benefit of the space when available but is not guaranteed. Limited to 24oz of coffee per day. First come-first-serve. Coffee making shall be the express responsibility of Numa approved managers/individuals unless otherwise posted at the designated coffee areas.
            <ol style={{ listStyleType: "lower-alpha", marginLeft: "2em" }}>
              <li>All members shall wash their own mugs</li>
            </ol>
          </li>
          <li>
            Kitchen: The kitchen is accessible by members with the following rules and a right by Numa to revoke access if rules are not followed:
            <ol style={{ listStyleType: "lower-alpha", marginLeft: "2em" }}>
              <li>
                All dishes must be washed at the time of engagement in the kitchen area
                <ol style={{ listStyleType: "lower-roman", marginLeft: "2em" }}>
                  <li>No dishes that are dirty, unwashed, have food/liquids remaining on/in them shall be left unattended in the kitchen space.</li>
                  <li>No dishes shall be left in the sink</li>
                  <li>All washed items left in the kitchen area must be left in the designated drying area</li>
                  <li>If the drying area is full, dishes are not to be piled beyond the drying area - bring it back to your desk.</li>
                </ol>
              </li>
              <li>Numa shall not be held responsible for any missing or damaged personal plates or mugs.</li>
              <li>
                Fridge
                <ol style={{ listStyleType: "lower-roman", marginLeft: "2em" }}>
                  <li>All items placed in the fridge must be labeled with the member’s name</li>
                  <li>All items in the fridge must be cleared out before EOD Friday</li>
                  <li>Numa reserves the right to discard any items in the fridge at any time</li>
                  <li>Numa shall not be held responsible for consumption/theft of any items left in the fridge - label your food</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            Workspace Environment
            <ol style={{ listStyleType: "lower-alpha", marginLeft: "2em" }}>
              <li>
                Noise
                <ol style={{ listStyleType: "lower-roman", marginLeft: "2em" }}>
                  <li>All members must maintain adequate noise levels appropriate for an office space.</li>
                  <li>Music shall not be played in open workspaces without use of headphones.</li>
                </ol>
              </li>
              <li>
                Dresscode
                <ol style={{ listStyleType: "lower-roman", marginLeft: "2em" }}>
                  <li>All members, guests, and individuals engaging in the space must dress in a way that is appropriate to an office space.</li>
                </ol>
              </li>
              <li>
                Access
                <ol style={{ listStyleType: "lower-roman", marginLeft: "2em" }}>
                  <li>Monthly members will be given access to the space 24/7 and will be responsible for leaving the workspace as they found it and following all standard security protocol.</li>
                  <li>Day-pass members, hourly rentals, and guests are only permitted to use the space during open business hours.</li>
                </ol>
              </li>
              <li>
                Guests
                <ol style={{ listStyleType: "lower-roman", marginLeft: "2em" }}>
                  <li>Guests are not permitted in the space unless expressly given permission by Numa.</li>
                  <li>Guests are permitted in the following cases:</li>
                  <ol style={{ marginLeft: "2em" }}>
                    <li>If the member bringing a guest has a designated office</li>
                    <li>If the guest is there for approved use of a conference room or office rental</li>
                  </ol>
                </ol>
              </li>
              <li>
                Security Protocol
                <ol style={{ listStyleType: "lower-roman", marginLeft: "2em" }}>
                  <li>Upon receiving membership you shall receive a key fob. Key fobs are exclusively for your personal use only and any exception to this will result in termination of your membership immediately. (*In the case of the loss of key fob you will pay a replacement fee of $20)</li>
                  <li>After hours access for members is through the market st entrance located next to the huntington drive through.</li>
                  <li>When leaving the facility after hours you are responsible for ensuring the door closes securely behind you. This is for your safety and the safety of other members. If there is ever a discrepancy, security footage will be reviewed.</li>
                  <li>Doors shall remain securely closed after hours and no one is permitted in the facility unless they can access themselves via key fob. (do not answer the door if someone is knocking or trying to get access without their own key)</li>
                  <li>In the case of a maintenance related issue please call (740)232-9678</li>
                  <li>In the case of emergency call 911</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>Numa Spaces LLC reserves the right to revoke membership and refund the member as necessary, terminating their membership, should the member violate any of the above rules, or for any reason, on a case by case basis.</li>
          <li>Numa Spaces LLC reserves the right to modify this document from time to time as deemed necessary.</li>
        </ol>
        <h2 className="h2" style={{ marginTop: "2em" }}>Code of Conduct</h2>
        Numa Spaces is a Catholic Co-working Cooperative. We are passionate about providing a positive, uplifting workspace that is evidently Catholic by the service offerings Numa provides, as well as by the individuals that engage in the cooperative. The following code of conduct is intended to support this culture. Numa reserves the right to revoke memberships for any reason, at any time.
        <ol style={{ paddingLeft: "2em" }}>
          <li>Language</li>
          <li>Attire</li>
          <li>Cleanliness &amp; organization</li>
          <li>Respect for others</li>
          <li>Respect for the facility</li>
          <li>Compliance with terms of service and membership agreement</li>
          <li>Compliance with security protocol</li>
          <li>All other sections listed in the Terms of Service and Membership Agreement</li>
        </ol>
        <b>THE INDIVIDUAL HEREBY ACKNOWLEDGES THAT BY REVIEWING THIS DOCUMENT, THEY ARE AGREEING TO A BINDING AGREEMENT TO THE TERMS HEREBY LAID OUT IN THIS DOCUMENT TO THE SAME DEGREE AND CONSIDERED REPLACING A SIGNATURE.</b>
      </div>
    </>

  )
}