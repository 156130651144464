import { Parallax } from 'react-parallax';
import { Link } from 'react-router-dom';
import HeroImage from '../images/numa-hero.JPG'
import deskMacWater from '../images/desk-mac-water.jpg';
import coffeeImg from '../images/jura.jpg';
import hotDesks from '../images/hot-desks.jpg';
import conferenceRoom from '../images/conference-room.jpg';

import './Landing.css';

export default function Index() {
  return (
    <>
      <Parallax
        className="hero-img"
        bgImage={HeroImage}
        bgImageAlt="hero"
        strength={200}
        bgImageStyle={{ opacity: '.5', objectFit: 'cover' }}
      >
        <div className="center-hero">
          <div>
            <h1 className="h1">
              <span>N</span>
              <span>U</span>
              <span>M</span>
              <span>A</span>
              <span> </span>
              <span>S</span>
              <span>P</span>
              <span>A</span>
              <span>C</span>
              <span>E</span>
              <span>S</span>
            </h1>
            <p className="hero-subheading">CATHOLIC COWORKING IN DOWNTOWN STEUBENVILLE</p>
          </div>
        </div>
      </Parallax>
      {/* QUICK STATEMENT */}
      <div className='page-section'>
        <div className="left-section">
          <h2 className='h2'>A workspace that fits your needs</h2>
          <p>Want to turn any job into a Catholic workplace?
            We offer a variety of options to help you work your best.
            Pick from open seating, designated desks, or even a full office.
          </p>
          <p style={{ marginBottom: '2rem' }}><b>Want more information?</b></p>
          <Link to={'/tours'} className="btn btn-dark">schedule a tour</Link>
        </div>
        <div className="right-section hide-mobile">
          <img src={deskMacWater} alt="desk, macbook, water bottle" />
        </div>
      </div>
      {/* CTA */}
      <Parallax
        className='page-section bg-black'
        bgImage={hotDesks}
        bgImageAlt="hero"
        strength={400}
        bgImageStyle={{ opacity: '.5', objectFit: 'cover' }}
      >
        <div className="single-section">
          <h2 className='h2'>Ready to join our community?</h2>
          <Link to={'/memberships'} className="btn">Sign up now</Link>
        </div>
      </Parallax>
      {/* FEATURES */}
      <div className='page-section'>
        <div className="left-section hide-mobile">
          <img src={coffeeImg} alt="coffee maker" />
        </div>
        <div className="right-section">
          <h2 className='h2'>included with every membership:</h2>
          <div style={{ marginLeft: '2rem', marginTop: '1rem' }}>
            <ul>
              <li>High-speed wifi</li>
              <li>Locally, fresh-roasted coffee and espresso</li>
              <li>Conference room use</li>
              <li>Speaking events at Numa</li>
              <li>Opportunities for regular Mass and Confessions</li>
              <li>All utilities (WiFi, Heating, Water, etc...)</li>
            </ul>
          </div>
        </div>
      </div>
      {/* CTA */}
      <Parallax
        className='page-section bg-black'
        bgImage={conferenceRoom}
        bgImageAlt="hero"
        strength={200}
        bgImageStyle={{ opacity: '.5', objectFit: 'cover' }}
      >
        <div className="single-section normal">
          <h2 className='h2'>Work surrounded by like-minded people. Build Community. Grow in faith.</h2>
          <Link to={'/memberships'} className="btn">Join Numa</Link>
        </div>
      </Parallax>
    </>
  );
}