import { Parallax } from "react-parallax";
import ContactUsForm from "../components/ContactUsForm";

import './Contact.css';

export default function Contact() {
  return (
    <>
      <Parallax
        className='page-section page-section-short bg-black'
        bgImage={'https://images.unsplash.com/photo-1542435503-956c469947f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80'}
        bgImageAlt="hero"
        strength={400}
        bgImageStyle={{ opacity: '.5', objectFit: 'cover' }}
      >
        <div className="single-section">
          <h1 className='h1'>Contact us</h1>
        </div>
      </Parallax>

      <div className="contact-us-section">
        <h2 className="h2">Send us a message</h2>
        <ContactUsForm />
        <h3 className="h3" style={{ marginTop: '2rem' }}>Need a faster response? <br /> Here is our contact info:</h3>
        <p><a href="tel:+17402329678">(740) 232-9678</a></p>
        <p><a href="mail:numaspacesllc@gmail.com">numaspacesllc@gmail.com</a></p>
      </div>
    </>
  )
}