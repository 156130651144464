import { useState } from 'react';
import { Link } from 'react-router-dom';
import NumaLogo from '../images/numa-logo-wordmark.png';

import './Navbar.css';

export default function Navbar() {
  const [navbarClicked, setNavbarClicked] = useState(false);

  return (
    <div className='navbar'>
      <Link to={'/'}><img src={NumaLogo} alt="numa logo" className="logo" /></Link>
      <div className="menu-wrap">
        <button className={navbarClicked ? 'toggler clicked' : 'toggler'} onClick={() => setNavbarClicked(!navbarClicked)}></button>
        <div className="hamburger"><div></div></div>
        <div className="menu">
          <div>
            <div>
              <ul>
                <li><Link onClick={() => setNavbarClicked(!navbarClicked)} className='menu-link' to={'/'}>HOME</Link></li>
                <li><Link onClick={() => setNavbarClicked(!navbarClicked)} className='menu-link' to={'/tours'}>SCHEDULE A TOUR</Link></li>
                <li><Link onClick={() => setNavbarClicked(!navbarClicked)} className='menu-link' to={'/memberships'}>MEMBERSHIPS</Link></li>
                <li><Link onClick={() => setNavbarClicked(!navbarClicked)} className='menu-link' to={'/contact'}>CONTACT US</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}