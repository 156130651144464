import { useForm, ValidationError } from '@formspree/react';

import './ContactUsForm.css';

export default function ContactUsForm({ message }) {
  const [state, handleSubmit] = useForm("mvoylngg");
  if (state.succeeded) {
    return <p>Thank you for reaching out! We will get back to you shortly.</p>;
  }

  return (
    <div className="form-wrapper">
      <form onSubmit={handleSubmit}>
        {/* Email */}
        <label htmlFor="email">
          Email Address
        </label>
        <input
          id="email"
          type="email"
          name="email"
        />
        <ValidationError
          prefix="Email"
          field="email"
          errors={state.errors}
        />
        {/* phone */}
        <label htmlFor="phone">
          Phone Number
        </label>
        <input
          id="phone"
          type="phone"
          name="phone"
        />
        <ValidationError
          prefix="Email"
          field="email"
          errors={state.errors}
        />
        {/* message */}
        <label htmlFor="message">
          {message ? message : "Message"}
        </label>
        <textarea
          id="message"
          name="message"
        />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />
        <button className='btn btn-dark' type="submit" disabled={state.submitting}>
          Submit
        </button>
      </form>
    </div>
  )
}