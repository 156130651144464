import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Root from "./routes/Root";
import Landing from "./routes/Landing";
import Memberships from "./routes/Memberships";
import Contact from "./routes/Contact";
import Tours from "./routes/Tours";
import HotDesk from "./routes/HotDesk";
import reportWebVitals from './reportWebVitals';
import ErrorPage from "./error-page";
import TOS from './routes/TOS';
import { ChakraProvider } from '@chakra-ui/react'
import {
  createBrowserRouter,
  RouterProvider,
  ScrollRestoration
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Landing /> },
      {
        path: "/memberships",
        element: <Memberships />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/tours",
        element: <Tours />,
      },
      {
        path: "/hot-desk",
        element: <HotDesk />,
      },
      {
        path: "/terms-of-service",
        element: <TOS />,
      },
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ChakraProvider>
    <RouterProvider router={router} />
  </ChakraProvider>
);

reportWebVitals();
