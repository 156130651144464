import { Parallax } from "react-parallax";
import ContactUsForm from "../components/ContactUsForm";
import hotDeskEntrance from "../images/hot-desks-entrance.jpg"

import './Tours.css';

export default function Contact() {
  return (
    <>
      <Parallax
        className='page-section page-section-short bg-black'
        bgImage={hotDeskEntrance}
        bgImageAlt="hero"
        strength={400}
        bgImageStyle={{ opacity: '.5', objectFit: 'cover' }}
      >
        <div className="single-section">
          <h1 className='h1'>schedule a tour</h1>
        </div>
      </Parallax>

      <div className="contact-us-section">
        <h2 className="h2">Please fill out the form below</h2>
        <p style={{ marginBottom: '1rem' }}>After you fill out the form, one of the
          founders of the coworking space will reach out to you
          to show you the space and learn more about your needs.
        </p>
        <ContactUsForm message="let us know what days and times work for you!" />
      </div>
    </>
  )
}