import { Link } from 'react-router-dom';
import NumaLogo from '../images/numa-logo-wordmark.png';

import './Footer.css';

export default function Footer() {
  return (
    <div className='footer'>
      <div className='footer-left'>
        <span className="footer-title">
          NUMA SPACES LLC <br />
          <span className='footer-subtitle'>CATHOLIC + COWORKING + COLLABORATIVE</span>
        </span>
        <span>100 N 4th Street, Steubenville, OH</span>
      </div>
      <div className="footer-right">
        <Link to={'/memberships'}>MEMBERSHIPS</Link>
        <Link to={'/contact'}>CONTACT</Link>
        <Link to={'/terms-of-service'}>TERMS OF SERVICE</Link>
      </div>
    </div>
  );
}